import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import IntakeForm from "./components/IntakeForm";
import "./App.scss";

export const booleanContext = React.createContext();

function App() {
  return (
    <div>
      <booleanContext.Provider value="en">
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={IntakeForm} />
          </Switch>
          <Footer />
        </Router>
      </booleanContext.Provider>
    </div>
  );
}

export default App;
