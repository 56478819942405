import React from "react";
import { Container, Card } from "react-bootstrap";

function Acknowledgement(props) {
  const { id } = props;

  return (
    <div>
      <Container className="my-5">
        <Card>
          <Card.Body className="ml-2 text-justify">
            <Card.Title className="mb-5 font-weight-bold">
              <u>
                <h3 className="text-center">Confirmation</h3>
              </u>
            </Card.Title>
            <div className="mb-5">
              <Card.Text>
                Your Privacy Request is submitted successfully and the ID is{" "}
                <strong>{id}</strong>. Please save a copy for your records.
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Acknowledgement;
