import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";

function RequestTypes(props) {
  const { requestTypes, onAccessRequestType, onUpdateRequestType } = props;
  const [checkedRequestTypes, setCheckedRequestTypes] = useState([]);
  const [relationshipType, setRelationshipType] = useState([]);

  const handleChange = (e) => {
    if (e.target?.value) {
      e.stopPropagation();
    }
    const inputValue = e.target?.value || e;
    const indexVal = relationshipType.indexOf(e.target?.id);
    if (indexVal === -1) {
      setRelationshipType([...relationshipType, e.target?.id]);
    } else {
      setRelationshipType(
        relationshipType.filter((ele) => ele !== e.target?.id)
      );
    }

    let currentRequestTypes = [...checkedRequestTypes];
    if (currentRequestTypes.indexOf(inputValue) === -1) {
      currentRequestTypes = [...currentRequestTypes, inputValue];
    } else {
      currentRequestTypes = currentRequestTypes.filter(
        (requestType) => requestType !== inputValue
      );
    }

    setCheckedRequestTypes(currentRequestTypes);
    props.onRequestTypesChange(currentRequestTypes);
  };

  useEffect(() => {
    if (relationshipType) {
      const checkElements = ["access", "correction"];
      if (checkElements.every((color) => relationshipType.includes(color))) {
        onUpdateRequestType(true);
        onAccessRequestType(true);
      } else if (relationshipType.includes("correction")) {
        onUpdateRequestType(true);
        onAccessRequestType(false);
      } else if (relationshipType.includes("access")) {
        onAccessRequestType(true);
        onUpdateRequestType(false);
      } else {
        onUpdateRequestType(false);
        onAccessRequestType(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipType]);

  return (
    <>
      <p className="my-3">
        <strong className="label-text">
          Contact Reason: <span className="text-danger">*</span>
        </strong>
      </p>
      <Row xs={1} className="g-3 mb-4">
        {requestTypes &&
          requestTypes.map((requestType, index) => {
            return (
              <Col key={`${requestType.value}`}>
                <Card className="card-requestType" key={`${requestType.value}`}>
                  <Card.Body>
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        value={`${requestType.value}`}
                        id={`${requestType.value}`}
                        onClick={handleChange}
                      />
                      <Form.Check.Label className="h6">
                        {requestType.description}
                      </Form.Check.Label>
                    </Form.Check>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}

export default RequestTypes;
