import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Recaptcha from "./ReCaptcha";
import { useForm, Controller } from "react-hook-form";
import { textValidation, selectValidation } from "./Validation";
import getPayload from "./getPayload";
import { getService } from "../../shared/components/restApi";
import { Constants } from "../../shared/components/Constants";
import RequestTypes from "./RequestTypes";

function RequestForm(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [vendorSelected, setVendorSelected] = useState(false);
  const [value, setValue] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [accessRequestType, setAccessRequestType] = useState(false);
  const [updateRequestType, setUpdateRequestType] = useState(false);
  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    requestFor,
    getRelationshipType,
    getRedirectURL,
  } = props;

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const handleVendorInput = (e) => {
    getRelationshipType(e.target.value);
    if (e.target.value === "Vendor") {
      setVendorSelected(true);
    } else {
      setVendorSelected(false);
    }
  };

  const { register, handleSubmit, formState, errors, validate, control } =
    useForm({
      mode: "onChange",
    });

  const onSubmit = (data) => {
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);
      console.log("sendRequestData", sendRequest);
      // payload
      const inputsForPayLoad = {
        selectedRequests,
        inputsData: sendRequest,
        configValues,
        recaptchaToken,
      };

      const payload = getPayload(inputsForPayLoad);
      console.log("payload", payload);

      // API request

      const url = process.env.REACT_APP_CURRENT_ENVIRONMENT_API_URL;

      const submitOptions = {
        method: `POST`,
        url,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
            getRedirectURL(response.data.redirectLink);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  const getResponseToken = (token) => {
    setRecaptchaToken(token);
  };

  const handleRequestTypesChange = (requestTypes) => {
    setSelectedRequests(requestTypes);
  };

  const handleAccessRequestType = (bool) => {
    setAccessRequestType(bool);
  };

  const handleUpdateRequestType = (bool) => {
    setUpdateRequestType(bool);
  };

  return (
    <div className="main-content">
      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          {/* <div className="form-title mb-3">
            <Card.Text>
              <strong>Please provide the following information to start your request:</strong>
            </Card.Text>
            <Card.Text>
              <span className="text-danger">*</span> Required fields
            </Card.Text>
          </div> */}
          <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
            {requestFor === "Myself" ? (
              <>
                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Relationship to Corteva{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Customer/Farmer/Retailer/Distributor"
                            name="relationshipToCorteva"
                            id="customerFarmer"
                            value="Customer/Farmer/Retailer/Distributor"
                            className="radio-label-item"
                            // className="left radio-label-item white-space-nowrap"
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Employee/Applicant/Former employee/Contractor"
                            name="relationshipToCorteva"
                            value="Employee/Applicant/Former employee/Contractor"
                            id="employee"
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Vendor"
                            name="relationshipToCorteva"
                            id="vendor"
                            value="Vendor"
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <RequestTypes
                  requestTypes={configValues.requestTypes}
                  onRequestTypesChange={handleRequestTypesChange}
                  onAccessRequestType={handleAccessRequestType}
                  onUpdateRequestType={handleUpdateRequestType}
                />

                {updateRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please specify what needs to be updated/corrected{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="updateRequestDetails"
                          className="mb-2"
                          placeholder="Please do not provide any sensitive personal information through this form"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                {accessRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please provide specifics about your request{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="accessRequestDetails"
                          className="mb-2"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        className={!errors.firstName ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.firstName && (
                        <small className="text-danger">
                          {" "}
                          {errors.firstName.message}{" "}
                        </small>
                      )}
                    </Col>

                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Last Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        className={!errors.lastName ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.lastName && (
                        <small className="text-danger">
                          {" "}
                          {errors.lastName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Company{" "}
                        {vendorSelected && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        className={!errors.company ? "mb-2" : "error-border"}
                        ref={register({ required: vendorSelected })}
                      />
                      {errors.company && (
                        <small className="text-danger">
                          {" "}
                          {errors.company.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <Form.Label className="fw-bold label-text">
                        Phone Number <span className="text-danger">*</span>
                      </Form.Label>

                      <Controller
                        as={
                          <PhoneInput
                            value={value}
                            country={"ca"}
                            onlyCountries={["ca"]}
                            onChange={(value) => setValue(value)}
                            inputProps={{
                              name: "phoneNumber",
                              required: true,
                            }}
                            ref={() => {
                              register({ required: true });
                            }}
                            name="phoneNumber"
                            placeholder=""
                            buttonClass="rounded"
                            inputStyle={{ width: "100%" }}
                          />
                        }
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "This field is required",

                          minLength: {
                            value: 10,
                            message: "A valid number is required",
                          },
                          maxLength: {
                            value: 12,
                            message: "A valid number is required",
                          },
                        }}
                      />
                      {errors.phoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.phoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        className={!errors.address ? "mb-2" : "error-border"}
                        ref={register({ required: true })}
                      />
                    </Col>

                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        City/Town
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="cityTown"
                        className={!errors.cityTown ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Province/Territory{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="province"
                        className={!errors.province ? "mb-2" : "error-border"}
                        ref={register(selectValidation)}
                      >
                        <option value="">Select Province/Territory</option>
                        {Constants.province.map((province, index) => (
                          <option key={index} value={province}>
                            {province}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.state && (
                        <small className="text-danger">
                          {" "}
                          {errors.state.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        Details
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="details"
                        className={!errors.details ? "mb-2" : "error-border"}
                        placeholder="Please include details to fully understand the request."
                        ref={register({ required: false })}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Intake Type <span className="text-danger">*</span>
                      </Form.Label>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Mail-in"
                            name="intakeType"
                            id="mailIn"
                            value="Mail-in"
                            className="radio-label-item"
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Call-in"
                            name="intakeType"
                            value="Call-in"
                            id="CallIn"
                            className="radio-label-item"
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-center mt-4">
              <Recaptcha
                reCaptchaClick={handleReCaptchaClick}
                responseToken={getResponseToken}
              />
            </div>

            <Form.Group>
              <Row className="d-flex justify-content-center mt-5">
                <Col sm={12} md={10} lg={10} className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    style={{
                      width: "inherit",
                    }}
                    disabled={
                      !formState.isValid ||
                      disableSubmit ||
                      validate ||
                      !selectedRequests.length
                    }
                  >
                    Submit Request
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RequestForm;
