// function to create the paylod with necessary fields based on request
export default function getPayload({
  selectedRequests,
  inputsData,
  configValues,
  recaptchaToken,
}) {
  let primaryEmail = inputsData["email"] ? inputsData["email"] : "";
  let providedFields = [];

  console.log("selectedRequests", selectedRequests);
  selectedRequests &&
    selectedRequests.forEach((request) => {
      for (let key in configValues.actions[request]) {
        if (key === "fields") {
          const dataFields = configValues.actions[request][key]
            .map((field) => {
              switch (field.label) {
                case "Relationship to Corteva":
                  return {
                    value: inputsData["relationshipToCorteva"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please specify":
                  return {
                    value: inputsData["otherText"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Details":
                  return {
                    value: inputsData["details"],
                    label: field.label,
                    _id: field._id,
                  };
                case "First Name":
                  return {
                    value: inputsData["firstName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Last Name":
                  return {
                    value: inputsData["lastName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Company":
                  return {
                    value: inputsData["company"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Phone Number":
                  return {
                    value: inputsData["phoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Address":
                  return {
                    value: inputsData["address"],
                    label: field.label,
                    _id: field._id,
                  };
                case "City/Town":
                  return {
                    value: inputsData["cityTown"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Province/Territory":
                  return {
                    value: inputsData["province"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Postal Zip Code":
                  return {
                    value: inputsData["postalZipCode"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Country":
                  return {
                    value: "Canada",
                    label: field.label,
                    _id: field._id,
                  };
                case "Please provide specifics about your request":
                  return {
                    value: inputsData["accessRequestDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please specify what needs to be updated/corrected":
                  return {
                    value: inputsData["updateRequestDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Intake Type":
                  return {
                    value: inputsData["intakeType"],
                    label: field.label,
                    _id: field._id,
                  };

                default:
                  console.warn(`unknown field ${field.label}`);
              }
              return null;
            })
            .filter((field) => field);

          providedFields = [...providedFields, ...dataFields];
        }
      }
    });
  const dataInputs = {
    data: {
      providedFields,
      primaryEmail,
      providedRequests: selectedRequests,
      recaptchaToken,
    },
    meta: {
      id: `${configValues.settings}`,
      pageDataId: `${configValues._id}`,
      type: "dsar",
    },
  };
  return dataInputs;
}
