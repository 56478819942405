import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import Acknowledgement from "./Acknowledgement";
import RequestForm from "../shared/components/RequestForm";
import { getService } from "../shared/components/restApi";

function IntakeForm() {
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [configValues, setConfigValues] = useState({});
  const [relationshipType, setRelationshipType] = useState("");
  const [redirectURL, setRedirectURL] = useState("");

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  const handleRedirectURL = (url) => {
    setRedirectURL(url);
  };

  const handleRelationshipType = (value) => {
    setRelationshipType(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    let privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER}`;
    if (relationshipType) {
      switch (relationshipType) {
        case "Customer/Farmer/Retailer/Distributor":
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER}`;
          break;
        case "Vendor":
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_VENDOR}`;
          break;
        case "Employee/Applicant/Former employee/Contractor":
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_EMPLOYEE}`;
          break;
        default:
          break;
      }
    }

    let configOptions = {
      method: "GET",
      url: privacyFormURL,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});

          let finalOrderedActions;

          // changing the order of actions object
          const objectTemp1 = {
            correction: null,
            deletion: null,
            portability: null,
            access: null,
            "optOut-data-processing": null,
          };
          const objectTemp2 = {
            optOut: null,
            correction: null,
            deletion: null,
            portability: null,
            access: null,
            "optOut-data-processing": null,
          };

          const finalObjTemp =
            relationshipType === "Employee/Applicant/Former employee/Contractor"
              ? objectTemp1
              : objectTemp2;

          if (Object.keys(actions).length > 0) {
            finalOrderedActions = Object.assign(finalObjTemp, actions);
          }

          const requestTypes = Object.keys(finalOrderedActions).map(
            (request) => {
              switch (request) {
                case "access":
                  return {
                    value: "access",
                    description:
                      "Inform me about the processing of my personal information",
                  };
                case "deletion":
                  return {
                    value: "deletion",
                    description: "Delete my personal information",
                  };
                case "correction":
                  return {
                    value: "correction",
                    description: "Update/correct my personal information",
                  };
                case "portability":
                  return {
                    value: "portability",
                    description: "Copy of my personal information",
                  };
                case "optOut":
                  return {
                    value: "optOut",
                    description: "Opt-out of marketing communications",
                  };
                case "optOut-data-processing":
                  return {
                    value: "optOut-data-processing",
                    description: "Withdraw of consent",
                  };
                case "category-access":
                  return {
                    value: "category-access",
                    description:
                      "Limit the use/disclosure of my sensitive personal information",
                  };
                default:
                  return console.warn("Unknown Request Type", request);
              }
            }
          );

          setConfigValues({
            actions,
            requestTypes,
            settings: settingsId,
            _id: settingsId,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [relationshipType]);

  return (
    <div>
      <SrrHeader heading="Privacy Request" />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} redirectURL={redirectURL} />
        ) : (
          <>
            <p className="text-center">
              <strong>
                Please fill out the form below to submit your request.
              </strong>
            </p>

            <RequestForm
              configValues={configValues}
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
              getRedirectURL={handleRedirectURL}
              getRelationshipType={handleRelationshipType}
              relationshipType={relationshipType}
              requestFor="Myself"
            />
          </>
        )}
      </Container>
    </div>
  );
}

export default IntakeForm;
