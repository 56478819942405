const textValidation = {
  required: "This field is required",
  minLength: {
    value: 2,
    message: "Minimum Length should be 2",
  },
  maxLength: {
    value: 20,
    message: "Maximum Length should be 20",
  },
  pattern: {
    value: /^[A-Za-z ]+$/i,
    message: "Please enter alphabets only",
  },
};

const selectValidation = {
  required: "This field is required",
};

const phoneValidation = {
  required: "This field is required",
  // maxLength: {
  //   value: 10,
  //   message: "Please enter valid 10 digit number only",
  // },
  // pattern: {
  //   value: /^[0-9]+$/i,
  //   message: "Please enter number only",
  // },
};

const emailValidation = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Please enter valid email",
  },
};

const zipcodeValidation = {
  required: "This field is required",
  pattern: {
    value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    message: " Please enter valid 5 digit Zip Code only",
  },
};
export {
  textValidation,
  phoneValidation,
  emailValidation,
  zipcodeValidation,
  selectValidation,
};
